<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="我举办的培训" name="first" >
      <MyTrain v-if="isFirst"></MyTrain>
    </el-tab-pane>
    <el-tab-pane label="其他培训" name="second">
      <OtherTrain v-if="isSecond"></OtherTrain>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import MyTrain from '@/views/trainManageF/trainListChildrend/MyTrain'
import OtherTrain from '@/views/trainManageF/trainListChildrend/OtherTrain'
export default {
  components:{MyTrain,OtherTrain},
  data() {
    return {
      activeName: 'first',
      isFirst: true,
      isSecond: true
    };
  },
  methods: {
    handleClick(tab, event) {
      if(tab.name == "first") {
        this.isFirst = true;
        this.isSecond = false;
      }
      else if(tab.name == "second") {
        this.isFirst = false;
        this.isSecond = true;
      }
    }
  }


}
</script>

<style scoped>

</style>
